import { Checkbox, DialogContent, FormControlLabel } from '@mui/material'
import { cvPersonSkillsAPI } from 'api/CvAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import newWithSimpleModal from 'Components/reusable/HOC/newWithSimpleModal'
import DeleteButton from 'Components/reusable/IconButtons/DeleteButton'
import SkillPicker from 'Components/reusable/InputFields/SkillPicker'
import { useNotification } from 'Components/reusable/Notification'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import { useLocalStorage } from 'hooks/data'
import _, { orderBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonSkillExperience, IPersonSkillOrIndustryOrRole, ISkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { CvId } from 'types/ids'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import SkillsItem from './SkillsItem'
import { TableInputField } from 'Components/reusable/InputFields/TableInputField'
import { useLoading } from 'Components/reusable/LoadingProvider'
import { ColumnDef, PaginationState, VisibilityState } from '@tanstack/react-table'
import { useIsComponentMounted } from 'hooks/util'
import { InputField } from 'Components/reusable/InputFields/InputField'
import { skillAPI } from 'api/SkillAPI'

interface SkillExperience extends IPersonSkillExperience {
  skill: { Skill: ISkillOrIndustryOrRole }
}

/** @notExported */
interface ISkillMassUpdateModalProps {
  /** Function for closing modal. */
  onClose: (updatedItems: IPersonSkillOrIndustryOrRole[]) => void
  /** Person skills. */
  personSkills: IPersonSkillOrIndustryOrRole[]
  /** CV ID. */
  cvId: CvId
  /** Whether submit button is pressed. */
  submitIndicator: boolean
  /** Selected person skills. */
  selectedPersonSkills: IPersonSkillExperience[]
  /** Type of skills. */
  type: string
  /** Function to set selected person skills. */
  setSelectedPersonSkills: (personSkills: IPersonSkillExperience[]) => void
}

/**
 * Modal for doing mass operations to skills.
 *
 * @returns Mass operation modal.
 * @notExported
 */
const SkillMassUpdateModal: React.FC<ISkillMassUpdateModalProps> = ({
  onClose,
  personSkills,
  cvId,
  submitIndicator,
  selectedPersonSkills,
  type,
  setSelectedPersonSkills,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [selectedSkills, setSelectedSkills] = useState<ISkillOrIndustryOrRole[]>([])
  const [experienceMonthsGained, setExperienceMonthsGained] = useState<number>(1)
  const [backendError, setBackendError] = useState<IError>()
  const { startLoading, endLoading } = useLoading()
  const { setSuccessNotification } = useNotification()
  const { t, i18n } = useTranslation()
  const [skillMassCount, setSkillMassCount] = useLocalStorage('skillMassCount', 10)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [updateLastUsed, setUpdateLastUsed] = useState<boolean>(false)
  const [skills, setSkills] = useState<ISkillOrIndustryOrRole[]>()

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: skillMassCount ? skillMassCount : 25,
  })

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      if (submitIndicator === true) {
        try {
          startLoading('skillMassUpdate')
          const updatedItems = await cvPersonSkillsAPI.massUpdate(
            cvId,
            { personSkills: selectedPersonSkills, updateLastUsed },
            controller
          )

          if (isComponentMounted.current) {
            onClose(updatedItems)
            setSuccessNotification()
          }
        } catch (error) {
          setBackendError(error as IError)
        } finally {
          endLoading('skillMassUpdate')
        }
      }

      return () => {
        controller.abort()
      }
    })()
  }, [submitIndicator])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        let newSkills: ISkillOrIndustryOrRole[] = []
        if (type === 'skill') {
          newSkills = await skillAPI.getAllSkills(controller)
        }

        if (type === 'industry') {
          newSkills = await skillAPI.getAllIndustries(controller)
        }

        if (type === 'role') {
          newSkills = await skillAPI.getAllRoles(controller)
        }

        if (!isComponentMounted.current) return

        const orderedSkills = orderBy(newSkills, [skill => chooseDBTranslation(i18n, skill).name], ['asc'])
        const sortToStart = personSkills.map(skill => skill.skillId)

        const sortedSkills = sortToStart.reduce((acc: ISkillOrIndustryOrRole[], id) => {
          const skill = orderedSkills.find(skill => skill.id === id)
          if (skill) {
            acc.push(skill)
          }
          return acc
        }, [])

        setSkills([...sortedSkills, ...orderedSkills.filter(skill => !sortToStart.includes(skill.id))])
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  const sortTypeFunction = (rowA: SkillExperience, rowB: SkillExperience) => {
    const a = chooseDBTranslation(i18n, 'level' in rowA ? rowA.Skill : rowA.skill.Skill).name
    const b = chooseDBTranslation(i18n, 'level' in rowB ? rowB.Skill : rowB.skill.Skill).name

    return a.localeCompare(b, i18n.language, { numeric: true, sensitivity: 'base' })
  }

  const handleSkillSelect = (selectedSkill: ISkillOrIndustryOrRole) => {
    const personSkillFound = personSkills.find(pSkill => pSkill.skillId === selectedSkill.id)

    let newSkill: IPersonSkillExperience

    if (personSkillFound) {
      newSkill = {
        id: personSkillFound.id,
        skillId: personSkillFound.skillId,
        Skill: selectedSkill,
        level: personSkillFound.level ?? 0,
        interestLevel: personSkillFound.interestLevel ?? 0,
        experienceMonths: personSkillFound.experienceMonths ?? 0,
        experienceMonthsGained: experienceMonthsGained,
        lastUsed: personSkillFound.lastUsed,
        createdAt: personSkillFound.createdAt,
        updatedAt: personSkillFound.updatedAt,
      } as IPersonSkillExperience
    } else {
      newSkill = {
        id: null as unknown,
        skillId: selectedSkill.id,
        Skill: selectedSkill,
        level: 3,
        interestLevel: 3,
        experienceMonths: 0,
        experienceMonthsGained: experienceMonthsGained,
        lastUsed: new Date().getFullYear(),
        createdAt: new Date(),
        updatedAt: new Date(),
      } as IPersonSkillExperience
    }

    setSelectedPersonSkills([...selectedPersonSkills, newSkill])

    setSelectedSkills([...selectedSkills, selectedSkill])
  }

  const setEditableSkill = skillId => {
    const updatedSkills = selectedPersonSkills.map(skill => {
      if (skill.skillId === skillId) {
        skill.currentlyEditable = true
      } else {
        skill.currentlyEditable = false
      }
      return skill
    })
    setSelectedPersonSkills(updatedSkills)
  }

  const changeExperienceGainedRow = (skillId, value) => {
    const val = value ? parseInt(value) : 0
    const updatedSkills = selectedPersonSkills.map(skill => {
      if (skill.skillId === skillId) {
        skill.experienceMonthsGained = val
      }
      return skill
    })
    debouncedChangeExperienceGainedRow(updatedSkills)
  }

  const changeExperienceGained = value => {
    const val = value ? parseInt(value) : 0
    const updatedSkills = selectedPersonSkills.map(skill => {
      skill.experienceMonthsGained = val
      return skill
    })
    setSelectedPersonSkills(updatedSkills)
    setExperienceMonthsGained(val)
  }

  const debouncedChangeExperienceGainedRow = useCallback(
    _.debounce(updatedSkills => setSelectedPersonSkills(updatedSkills), 1000),
    []
  )

  const deleteSkillRow = (skillId: number) => {
    const updatedPersonSkills = selectedPersonSkills.filter(skill => skill.skillId !== skillId)
    const updatedSkills = selectedSkills.filter(skill => skill.id !== skillId)
    setSelectedPersonSkills(updatedPersonSkills)
    setSelectedSkills(updatedSkills)
  }

  const columns = useMemo<ColumnDef<IPersonSkillExperience>[]>(
    () => [
      {
        id: 'nameColumn',
        header: t(`profile.${type}.card.nameColumn`),
        sortingFn: (rowA, rowB) => sortTypeFunction(rowA.original as SkillExperience, rowB.original as SkillExperience),
        accessorFn: row => (row.Skill ? chooseDBTranslation(i18n, row.Skill).name : ''),
        cell: ({ row }) => {
          return (
            <SkillsItem
              skill={row.original as unknown as IPersonSkillOrIndustryOrRole}
              editable={false}
              showTooltip={true}
              key={row.id}
              showLastUsed={true}
              type={type}
            />
          )
        },
      },
      {
        id: 'lastUpdated',
        header: t(`profile.${type}.massUpdate.lastUpdated`),
        accessorFn: row => `${row.updatedAt}`,
        cell: ({ row }) => {
          if (row.original.id) {
            return convertToDisplayDate(row.original.updatedAt ?? new Date())
          } else {
            return <CardContentText type="error">{t(`profile.${type}.massUpdate.newSkill`)}</CardContentText>
          }
        },
      },
      {
        id: 'experienceGained',
        header: t(`profile.${type}.massUpdate.experienceGained`),
        enableSorting: false,
        enableGlobalFilter: false,
        cell: ({ row }) => {
          if (row.original.currentlyEditable) {
            return (
              <TableInputField
                onChange={newValue => changeExperienceGainedRow(row.original.Skill.id, newValue)}
                type="number"
                value={row.original.experienceMonthsGained}
                helperText={t(`profile.${type}.months`)}
              />
            )
          } else {
            return (
              <>
                {row.original.experienceMonthsGained} {t(`profile.${type}.months`)}
              </>
            )
          }
        },
      },
      {
        id: 'controls',
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <DeleteButton
              clickAction={e => {
                e?.stopPropagation()
                deleteSkillRow(row.original.Skill.id)
              }}
            />
          )
        },
      },
    ],
    [selectedPersonSkills, i18n.language]
  )

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent>
      <InputField
        onChange={e => changeExperienceGained(e.target.value)}
        type="number"
        variant="outlined"
        InputProps={{ inputProps: { min: 1 } }}
        value={experienceMonthsGained}
        insetLabel
        label={t(`profile.${type}.massUpdate.experienceMonthsGained`)}
      />
      {skills && skills.length && (
        <SkillPicker
          onChange={selectedSkill => {
            if (selectedSkill) handleSkillSelect(selectedSkill)
          }}
          value={null}
          placeholderLabel={t(`profile.${type}.massUpdate.searchSkills`)}
          usedSkills={selectedSkills}
          type={type}
          insetLabel
          options={skills}
          resetOnSelect
        />
      )}
      <FormControlLabel
        label={t('profile.skill.massUpdate.updateLastUsed')}
        control={
          <Checkbox
            inputProps={{
              'aria-label': 'checkbox-update-last-used',
            }}
            checked={updateLastUsed}
            onChange={event => setUpdateLastUsed(event.target.checked)}
          />
        }
      />
      {selectedPersonSkills.length > 0 && (
        <CustomTable
          columns={columns}
          data={selectedPersonSkills}
          expanded={true}
          rowHover={true}
          rowClickAction={row => {
            if (!row.original.currentlyEditable) {
              setEditableSkill(row.original.Skill.id)
            }
          }}
          rowCount={skillMassCount}
          setRowCount={setSkillMassCount}
          initialPageSize={10}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
          newStyle
          elevation={0}
        />
      )}
    </DialogContent>
  )
}

export default newWithSimpleModal(SkillMassUpdateModal)
